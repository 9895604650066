//Language : javascript

import React from "react";
import NavItem from "./NavItem";

export default class NavBar extends React.Component {
	// constructor(props) {
	// 	super(props);
	// this.state = {
	// 	active: window.innerWidth > 870,
	// 	currentPage: "Home",
	// };
	// this.links = [
	// 	{ name: "Home" },
	// 	{ name: "Gallery" },
	// 	{ name: "Fitness" },
	// 	{ name: "Melandro" },
	// 	{ name: "Tik Tok Dances" },
	// 	{ name: "Brink" },
	// 	{ name: "???" },
	// ];
	// }

	// handleNavToggle() {
	// 	this.setState({ active: !this.state.active });
	// }

	render() {
		const navItems = this.props.links.map((item, index) => {
			return (
				<NavItem
					key={index}
					name={item.name}
					active={this.props.active === item.name}
					onClick={() => this.props.handleNavClick(item.name)}
				/>
			);
		});

		return (
			<nav className="navbar">
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav">{navItems}</ul>
				</div>
			</nav>
		);
	}
}
