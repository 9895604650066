import React from "react";
import Gif from "./gif.gif";
import Siren from "./siren.gif";
import Connor1 from "./Connor0.jpeg";
import Connor2 from "./Connor1.jpeg";
import "./Maintenance.css";

export default function Maintenance() {
	return (
		<div className="maintenance">
			<h1 className="halt">HALT!!!</h1>
			<img src={Gif} alt="" className="maintenance-gif"></img>
			<h2 className="halt-reason">Website Under Maintenance</h2>
			<img src={Siren} alt="" className="siren1"></img>
			<img src={Siren} alt="" className="siren2"></img>
			<img src={Connor1} alt="" className="connor1"></img>
			<img src={Connor2} alt="" className="connor2"></img>
		</div>
	);
}
