import React from "react";
import styles from "./ResizeBar.css";

const ResizeBar = (props) => {
	const min = 1;
	const max = 100;
	const width = props.width;

	return (
		<div className="resize-bar">
			<input
				type={"range"}
				min={min}
				max={max}
				value={width}
				className={"slider"}
				onChange={props.handleResize}
			></input>
		</div>
	);
};

export default ResizeBar;
