import React from "react";
import Message from "./Message";

export default function Screen(props) {
	const messages = props.messages.map((message, index) => {
		return (
			<Message
				text={message}
				from={index % 2 === 0 ? "from-me" : "from-them"}
				key={index}
			/>
		);
	});

	return (
		<div className="screen imessage" id="screen">
			{messages}
		</div>
	);
}
