import React from "react";
import image from "./Connor.jpeg";

export default class ConnorImg extends React.Component {
	render() {
		const styles = {
			width: this.props.width,
			height: "500px",
		};

		return <img style={styles} src={image} alt="Connor" />;
	}
}
