import React from "react";
import Tab from "../../Tab";

export default function Body() {
	return (
		<div className="body">
			<h2>A lover of Literature, Not Just Cookbooks</h2>
			<div className="literature">
				<p>
					<Tab /> For this section I would like to give a brief
					opinion on five pieces of literature of which I have in fact
					read somewhat recently. Yes… I can read
				</p>
				<br />
				<p>
					<strong>Animal Farm</strong>→ I had read this book on my own
					the summer before I read it sophomore year. I find it a
					valid though questionable critique of the Soviet Union.
					Whilst I find myself truly admiring the stylistic writing of
					Orwell, I do find the allegory to be questionable at points.
					Though certainly a classic, I feel it simplifies complex
					ideas for the average reader but to the detriment of the
					exploratory allegory Orwell perhaps was going for.
				</p>
				<br />
				<p>
					<strong>Lord of the Flies</strong>→ Golding presents his
					view of the human psyche through the means of a masterful
					work about the devolving civility of once civilized boys.
					Whilst I respect the depiction of humanity as derived from
					his experiences I do find it to be flawed. He presents the
					notion of an innately bad human nature and a struggle to
					suppress it, whilst under the pretense of his characters and
					scenarios that seem plausible, do not translate to reality.
				</p>
				<br />
				<p>
					<strong>The Great Gatsby</strong>→ An absolutely mesmerizing
					view of a bigon age. Fitzgerald brilliantly critiques the
					1920s in a compelling manner. A tragedy of misunderstanding
					perpetuated by the disregard present in the era. While I do
					have my critiques that arise from the simplicity of the
					novel, they can easily be overlooked given the beauty that
					lies in how simple the novel is. (My second favorite book)
				</p>
				<br />
				<p>
					<strong>Macbeth</strong>→ I like everyone else can
					appreciate historical fiction, whereas most of this genre
					insert themself alongside history Shakespeare blatantly
					disregards it. If one is willing to overlook such historical
					contradictions then and only then can this tragedy be
					appreciated. Its depiction of its main character driven to
					act out of character by his own attributes is rather
					interesting and thought provoking.
				</p>
				<br />
				<p>
					<strong>The Catcher in the Rye</strong>→ An absolutely
					astonishing novel. Salinger is immensely elegant yet concise
					in his critique of the world which shaped his writing of
					this masterpiece. An explicit view of the unrelenting world
					in which its main character is confined. Challenging
					societal woes, Salinger celebrates independent thinking in
					an era of conformity. His ability to command the reader's
					love for a fundamentally flawed character is utterly
					aweinsping. This is a must read for anyone who values an
					insightful perspective that can not be found elsewhere. (By
					far my favorite book)
				</p>
			</div>
			<h2>An Author at Heart</h2>
			<div className="author">
				<Tab />
				This website consists of a rather large quantity of writing, it
				is painstaking work but nothing I’m unfamiliar with. I have
				written a plethora of short pieces almost exclusively for
				entertainment purposes. I have recently stumbled upon two poems
				I had written. I will share one of them here, it reads as
				follows…
				<br />
				<div className="poem-container">
					<div className="poem">
						<p>
							<em>
								“I spend my hours, most in thought of you
								longingly. Eagerly awaiting our next union, our
								departure looms largely.
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								Shall I ever see you again remains yet unclear.
								I can only dream the hour near.
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								Paranoia reigns supreme. You’re but an image
								left to my fleeting dream.
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								I dream of joining you in a warm embrace. As I
								would gaze into that ever dreamy face
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								Can I hold you, all to let you go at the end.
								Can I let go of that feeling of when your arms
								first extend.
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								Our love is one for the ages. In the book of our
								love, generations will turn the pages.
							</em>
						</p>
						<br /> <br />
						<p>
							<em>
								Nothing can haunt our benevolent love. It soars
								endlessly as an elegant dove.”
							</em>
						</p>
					</div>
					<br />
				</div>
				<p>
					<Tab />
					This as everything else I write in my free time was of
					course written as a joke for a friend though if I’m being
					completely honest I don't think it's half bad, it’s terrible
					but it’s supposed to be and I rather like the effective
					rhyming without compromising the word choice.
				</p>
			</div>
			<h2>An Idiot and his Idioms</h2>
			<div className="idioms">
				<p>
					<Tab />
					The cats out of the bag, I love idioms. An admirably concise
					means of communicating a larger meaning via the use of
					arbreturary phrases yet linguistically understood. I
					couldn’t come up with something so ingenious until when pigs
					fly. I find myself in quite the pickle; to fully explain my
					love for idioms, you’ll be waiting till the cows come home
					for me to actually finish this thing. So with that in mind
					I’m not gonna beat around the bush, I’m gonna grab the bull
					by the horns and be about as subtle as a bull in a china
					shop about how much I love idioms. However this goes on I’m
					afraid it will turn into me beating a dead horse so I’ll try
					to keep this brief. Via using the idioms while describing my
					love for them I suppose I’m killing two birds with one
					stone. Once I get going, writing about idioms is a cakewalk.
					I don’t mean to rock the boat but people who don’t like
					idioms really get my goat. You know theres so much wrong in
					the world and I’m writing about idioms, I don’t wanna look
					like I’m playing the fiddle while Rome burns, I just don’t
					want to be a wet blanket and write about everything between
					the devil and the deep blue sea. I’m beginning to scrape the
					bottom of the barrel for these idioms, it’s a tall order to
					write all this so I think I’m gonna hit the hay.
				</p>
			</div>
			<h2>The Rectum & Me</h2>
			<p>
				<Tab />
				Those who know me well know that I have had a complex,
				intricate, and delicate relationship with my bowels. For well
				over a year and a half I have suffered bloody stool (And
				infrequent yet extreme constipation). Alas with all my bowel
				abnormalities, I had refused to see any medical professional to
				aid me in my rectal crisis. That choice left me with very little
				answers so speculation began to run rampant. The logical
				conclusion was hemorrhoids but colon cancer could not be
				completely dismissed. Alas, one fateful day, 4/28/2022 to be
				exact, my toilet became sight to among the most ungodly horrors
				of which I have ever borne witness. Many have seen the
				photograph taken of the horror [if you haven’t yet but would
				like to feel free to inquire next time you see me], blood was
				the only thing present in the toilet that morning at 6:24am. It
				was simultaneously a concerning amount but also not really that
				much. Nonetheless this along with the petitioning of others
				compelled me to finally seek medical attention.
			</p>
			<p>
				<Tab />
				First was City MD who referred me to the ER, then the ER
				subjected me to the single most uncomfortable action to be
				conjured by mankind and paraded around as a medical procedure.
				The doctor had inserted his index finger into my rectal cavity…
				The worst part is, that’s not even how they found the problem.
				It was only after they laid me in the fetal position with my
				buttox exposed with the instructions to “push” that they found
				two small hemorrhoids and a small tear. Those who know the story
				well know that I am to this day adamant that the tear was not
				present until they started inserting that wretched finger up my
				one-way road.
			</p>
			<p>
				<Tab />
				As uncomfortable and unforgettable an experience that was, it
				had settled the question. Since that fateful day there has been
				no blood passed since even though there was no treatment
				provided. I like to think that was the hemorrhoids grand finale.
				Even then I still have an upcoming appointment with the
				gastroenterologist (which is a word I learned that day).
			</p>
			<div className="videos">
				<h2>The Dissection Section</h2>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/N2yUg8EwB0k"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<h2>Unassumingly Intellectual</h2>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/W6j0Etq_Nek"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/coYzgPEbzig"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/Kthk0qHMyys"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/3vwShQKt6Xw"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/Y2zyUidLeoE"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<h2>The Muffin Incident</h2>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/JUvnoiT1GBA"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</div>
			<h3>Credits</h3>
			<p>
				Creative Aspects: Connor Falkowski
				<Tab />
				<Tab />
				Programming: Ty McKeon
			</p>
			<p className="credits">
				Niko Argerakis, Mr. Brink, James Cerone, Alexa Cheskay, Jada
				Drew, Gianna^2, Dr. Kennedy, Mrs. Melandro, Christian Mercado,
				Luis Rangel, Joseph Scimeca, Siddharth Sheth, Matt “The Wein”
				Weiner
			</p>
		</div>
	);
}
