import React from "react";
import rightAd from "./right-ad.JPG";

const RightAd = () => {
	return (
		<div className="gut-grabber">
			<img src={rightAd} alt="Gut Grabber Advert"></img>
		</div>
	);
};

export default RightAd;
