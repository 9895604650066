import React from "react";
import Tab from "../../Tab";

export default function Intro() {
	return (
		<div className="intro">
			<h2>
				<strong>Welcome to CF Interactive</strong>
			</h2>
			<p>
				<Tab />
				Congratulations, you have successfully discovered my little
				corner of the internet. A true hidden gem, this site serves but
				one purpose, that being to provide the full Connor Falkowski
				experience for all to access at your own whim. Prepare to
				indulge in the one, the only, the over the top, <em>Connor</em>.
			</p>
		</div>
	);
}
