import React from "react";
import Intro from "./MainText/Intro";
import Resize from "./Resize/Resize";
import Body from "./MainText/Body";
import Chat from "./InteractiveChat/Chat";

export default function Home(props) {
	return (
		<>
			<Intro />
			<Resize />
			<Chat />
			<Body />
		</>
	);
}
