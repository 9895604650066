import React from "react";
import styles from "./CloseButton.css";

const CloseButton = () => {
	return (
		<div className="close-button">
			<span></span>
			<span></span>
		</div>
	);
};

export default CloseButton;
