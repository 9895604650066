import React from "react";

export default function Shorts() {
	return (
		<div className="shorts">
			<h2>Shorts</h2>
			<div className="videos">
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/SF-TfWpEgSQ"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/c5G5xUt3Pcs"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/a__CMerhzdU"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						width="449"
						height="799"
						src="https://www.youtube.com/embed/imdPSrnTyHI"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</div>
		</div>
	);
}
